import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';

declare var window: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  loginChanged: Subject<boolean> = new Subject<boolean>();
  userObjectChanged: Subject<any> = new Subject<any>();

  isLoggedIn = false;
  loading: HTMLIonLoadingElement;
  progressNotificationId: number;
  language: string;
  country: string = 'in';
  phoneData: any = {};
  loader = false;
  userData: any;
  appSettings: any;
  isInternet = true;

  appInfo: { getAppName: string; getPackageName: string; getVersionCode: string | number; getVersionNumber: string; };

  constructor(
    public toastController: ToastController,
    private _router: Router,
    public modalController: ModalController,
    private http: HttpClient,
    public loadingController: LoadingController,
    private apiService: ApiService,
    private alertController: AlertController,
    private location: Location,
  ) { 
  }



  async presentToast(message: string, duration: number = 2000) {
    const toast = await this.toastController.create({
      message: message || '',
      duration: duration
    });
    toast.present();
  }

  async presentLoading(message: string, notLimit = false) {
    const options: any = {
      message,
      duration: 1000 * 20
    };
    if (!notLimit) {
      options.duration = 1000 * 20
    }
    this.loading = await this.loadingController.create(options);
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }


  moveTo(url: string) {
    this._router.navigateByUrl(url, { replaceUrl: true });
  }

  goBack() {
    this.location.back();
  }
  
  sortArrayFunction = (array: any[], field: string, dir: string = "asc"): any[] => {
    if (!array || array && array.length === 0) {
        return [];
    }
    const order = (dir === "desc") ? 1 : -1;
    array.sort((a1, b1) => {
        if (a1[field] < b1[field]) {
            return order;
        } else if (a1[field] > b1[field]) {
            return order * -1;
        } else {
            return 0;
        }
    });
    return array;
  }
}
