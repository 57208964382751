import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { CommonService } from './services/common.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  
  currentPageTitle = 'Dashboard';

  appPages = [];
  defaultAppPages = [
    {
      title: 'Dashboard',
      url: '',
      icon: 'easel'
    },
    {
      title: 'Order',
      url: '/order-list/order',
      icon: 'document-text'
    },
    {
      title: 'Delivery',
      url: '/order-list/delivery',
      icon: 'bicycle'
    },
    // {
    //   title: 'Day Inventory',
    //   url: '/day-inventory',
    //   icon: 'list'
    // },
    // {
    //   title: 'Sellers',
    //   roles: ["admin"],
    //   url: '/seller-list',
    //   icon: 'list'
    // },
    // {
    //   title: 'Inventory',
    //   roles: ["admin"],
    //   url: '/inventory',
    //   icon: 'list'
    // },
  ];
  token: string;
  userDoc: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private commonService: CommonService,
    private router: Router,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.userDoc = jwt_decode(this.token);
      this.appPages = JSON.parse(JSON.stringify(this.defaultAppPages)).filter((ele) => (ele && (!ele.roles || ele.roles.includes(this.userDoc.userType))));
    }
    this.commonService.loginChanged.subscribe((data: any) => {
      this.token = data;
      if (this.token) {
        this.userDoc = jwt_decode(this.token);
        this.appPages = JSON.parse(JSON.stringify(this.defaultAppPages)).filter((ele) => (ele && (!ele.roles || ele.roles.includes(this.userDoc.userType))));
      }
    });
  }

  ionViewWillEnter(){
    
  }

  initializeApp() {
    if (!localStorage.getItem("token")) {
      this.router.navigateByUrl("/login");
    }
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl("/login")
  }
}
