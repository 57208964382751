// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // mediaUrl:"https://api.zerotimeshop.com/",
  // serverUrl: "https://apiv2.zerotimeshop.com/api/v1/"
  mediaUrl:"https://api.healthcarepharmacy.in/",
  serverUrl: "https://api.healthcarepharmacy.in/api/v1/"
  // serverUrl: "http://localhost:3312/api/v1/"
  //  serverUrl: "http://172.105.56.20:3018/api/v1/"

  // serverUrl: "http://172.105.56.20:3018/api/v1/"
  // serverUrl: "https://apiv2.zerotimeshop.com/api/v1/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
