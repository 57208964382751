import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  appInfo: any = {};
  errorCount = 0;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  async getHttpHeaders(header: any = {}): Promise<HttpHeaders> {
    const auth = localStorage.getItem("token");
    if (auth) {
      header['Authorization'] = auth;
    }


    if (this.appInfo && this.appInfo.getVersionNumber) {
      header['f-version'] = this.appInfo.getVersionNumber;
    }
    // : this.userToken,

    return new HttpHeaders(header);
  }
  
  getRequest(getUrl: string, headerObj: any = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const headers = await this.getHttpHeaders(headerObj);
          this.http.get(`${environment.serverUrl}${getUrl}`, { headers })
            .subscribe((data: Response) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
      } catch (err) {
        reject(err);
      }
    });
  }

  patchRequest(getUrl: string, updatedData: any, headerObj: any = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
          if (getUrl && updatedData) {
          const headers = await this.getHttpHeaders(headerObj);
            this.http.patch(`${environment.serverUrl}${getUrl}`, updatedData, { headers })
              .subscribe((data) => {
                this.buildBackendResponse(data, resolve, reject);
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
      } catch (err) {
        reject(err);
      }
    });
  }

  putRequest(getUrl: string, updatedData: any, headerObj: any = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
          if (getUrl && updatedData) {
            const headers = await this.getHttpHeaders(headerObj);
            this.http.put(`${environment.serverUrl}${getUrl}`, updatedData, { headers })
              .subscribe((data) => {
                this.buildBackendResponse(data, resolve, reject);
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
      } catch (err) {
        reject(err);
      }
    });
  }

  postRequest(getUrl: string, updatedData: any, headerObj: any = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
          if (getUrl && updatedData) {
            const headers = await this.getHttpHeaders(headerObj);
            this.http.post(`${environment.serverUrl}${getUrl}`, updatedData, { headers })
              .subscribe((data) => {
                this.buildBackendResponse(data, resolve, reject);
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteRequest(deleteUrl: string, headerObj: any = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
          const headers = await this.getHttpHeaders(headerObj);
          this.http.delete(`${environment.serverUrl}${deleteUrl}`, { headers })
            .subscribe((data: Response) => {
              if (data && data['success']) {
                resolve(data['success']);
              } else {
                reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
              }
              // this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
        
      } catch (err) {
        reject(err);
      }
    });
  }

  buildBackendResponse(data: any, resolve: any, reject: any, extraData: any = {}): void {
    if (data && data['success']) {
      if (data['data']) {
        if (extraData && Object.keys(extraData).length) {
          resolve({...data['data'], ...extraData});
        } else {
          resolve(data['data']);
        }
      } else {
        reject('Response is empty. Please check the configuration');
      }
    } else {
      reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
    }
  }

  async checkForInternetSpeed() {
    console.log((<any>window).navigator.connection.downlink);
    if ((<any>window).navigator.connection.downlink < 0.1) {
      this.errorCount = 0;
      alert("NO_INTERNET_PLEASE_CONNECT_TO_INTERNET");
    }
  }

  buildHttpError(err: any, reject: any): void {
    if (err && err.status === 0) {
      this.errorCount++
      this.checkForInternetSpeed();
      if (this.errorCount > 5) {
        this.errorCount = 0;
        console.error("backend down error ", err);
        reject("OUR_BACKEND_IS_DOWN");
      }
    } else {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigateByUrl("/login")
      }
      console.log("err", err);
      reject((err && err.error && (err.error.message || err.error.error)) || err.message || 'Error Occured while fetching the data');
    }
  }
}
